<template>
  <div>
    <div class="loading-wrap">
      <div class="inner-wrap" :class="{ isActive: loading }">
        <circle2></circle2>
      </div>
    </div>

    <!-- Pager Header -->
    <!-- Titlebar -->
    <div class="parallax-container">
      <div class="parallax titlebar">
        <div id="titlebar">
          <div class="container">
            <div class="row d-flex align-itmes-center">
              <div class="col-md-12">
                <h2>{{ this.$route.meta.title }}</h2>
                <!-- Breadcrumbs -->
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <router-link to="/">الرئيسية</router-link>
                    </li>
                    <li>{{ this.$route.meta.title }}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <parallax :speed-factor="0.3" breakpoint="(min-width: 80px)">
        <img :src="titleBg" alt="Parallax Image"/>
      </parallax>
    </div>

    <b-container class="container">
      <div class="row" id="user-page-wrap">
        <!-- Widget -->
        <div class="col-md-5 col-lg-4 sidebar-wrapper">
          <div class="sidebar user-sidebar left">
            <div class="qrcode-wrap">
              <div v-if="owner === 0" class="d-flex align-items-center">
                <i class="ml-2 hi-qrcode2 font-18"></i>
                <h5>
                  سيظهر رمز الكيو ار(QR Code) الخاص بك عند اضافتك لأول عقار
                </h5>
              </div>
              <div
                v-else
                class="d-flex flex-column justify-content-center align-items-center "
              >
                <div><img :src="imageQr" class="mb-2" alt="QR Scan Image"/></div>
                <qrcode-vue
                  :value="qrcode.value"
                  :size="qrcode.size"
                  level="H"
                ></qrcode-vue>
                <div class="qrcode-info text-center">
                  <h3 class="mb-1">كود QR واحد لجميع عقاراتك</h3>
                  <p>يمكنك قراءة الـ QR كود وطباعته لسهولة الوصول لعقاراتك</p>
                </div>
              </div>
            </div>
            <div class="my-account-nav-container">
              <b-nav vertical class="my-account-nav">
                <b-nav-text class="sub-nav-title">ادارة الحساب</b-nav-text>
                <b-nav-item to="/profile"
                  ><i class="hi-user"></i> الملف الشخصي</b-nav-item
                >
                <!-- <b-nav-item><i class="hi-alarm"></i>التنبيهات</b-nav-item> -->
                <b-nav-item to="/change-password"
                  ><i class="hi-lock"></i> تغيير كلمة المرور</b-nav-item
                >
              </b-nav>

              <b-nav vertical class="my-account-nav">
                <b-nav-text class="sub-nav-title">ادارة العقارات</b-nav-text>
                <b-nav-item
                  :to="{ name: 'properties', params: { id: user._id } }"
                  ><i class="hi-apartment"></i> عقاراتي
                </b-nav-item>
                <b-nav-item
                  :to="{ name: 'properties', params: { id: 'my-favorites' } }"
                  ><i class="hi-bookmark"></i> مفضلتي
                </b-nav-item>
                <!-- <b-nav-item :to="{name: 'my-requests'}"><i class="hi-paper-plane"></i> طلباتي</b-nav-item> -->
                <b-nav-item to="/add-property/new"
                  ><i class="hi-plus"></i> اضافة عقار جديد</b-nav-item
                >
              </b-nav>

              <b-nav vertical class="my-account-nav">
                <b-nav-item @click="loggedOut"
                  ><i class="hi-power-switch"></i> تسجيل الخروج</b-nav-item
                >
              </b-nav>
            </div>
          </div>
        </div>

        <div class="col-md-7 col-lg-8">
          <!-- <div class="row"> -->
          <div class="my-profile my-account col-md-12">
            <h4 class="margin-top-0 mb-3">تغيير كلمة المرور</h4>

            <!-- <div class="notification notice">
                          <p>حاول ان لا تقل كلمة مرورك عن 12 حرفا على الاقل</p>
                        </div> -->

            <div class="row">
              <div class="col-12">
                <label for="prevPassword"
                  >كلمة المرور الحالية
                  <i class="hi-lock"></i>
                  <b-form-input
                    id="prevPassword"
                    ref="prevPassword"
                    v-model="prevPassword"
                    class="input-text"
                    :class="{ 'has-error': $v.prevPassword.$error }"
                    :type="prevPasswordFieldType"
                    placeholder="ادخل كلمة المرور الحالية"
                    @blur="$v.prevPassword.$touch()"
                  />
                  <i
                    class="show-hide-password"
                    :class="{
                      'hi-eye': prevPasswordFieldType === 'password',
                      'hi-eye-crossed': prevPasswordFieldType === 'text'
                    }"
                    @click="prevPasswordSwitchVisibility"
                  ></i>
                </label>
                <!-- <span v-if="submitted && !$v.prevPassword.goodPassword" class="invalid-feedback d-block">يجب الا تقل عن ٨ أحرف وتحتوي على حرف كبير وأخر صغير ورقم.</span> -->
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="password"
                  >كلمة المرور الجديدة
                  <i class="hi-lock"></i>
                  <b-form-input
                    id="password"
                    ref="password"
                    v-model="password"
                    class="input-text"
                    :class="{ 'has-error': $v.password.$error }"
                    :type="passwordFieldType"
                    placeholder="ادخل كلمة المرور الجديدة"
                    @blur="$v.password.$touch()"
                  />
                  <i
                    class="show-hide-password"
                    :class="{
                      'hi-eye': passwordFieldType === 'password',
                      'hi-eye-crossed': passwordFieldType === 'text'
                    }"
                    @click="switchVisibility"
                  ></i>
                </label>
                <span
                  v-if="submitted && !$v.password.goodPassword"
                  class="invalid-feedback d-block"
                  >يجب ألا تقل كلمة المرور عن 6 أحرف</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="onSubmitForm"
                  :disabled="spinnerLoading"
                  class="button mt-5 mb-2"
                >
                  <span>حفظ التغيرات</span>
                  <b-spinner
                    v-show="spinnerLoading"
                    small
                    class="mr-2"
                  ></b-spinner>
                </b-button>
              </div>

              <!-- <div style="direction: ltr">
                                {{passwordData}}
                            </div> -->
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </b-container>

    <div class="margin-top-55"></div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { Circle2 } from 'vue-loading-spinner'
import Parallax from 'vue-parallaxy'
import ResizeMixin from '@/mixins/ResizeHandler'
import { realEstateService } from '../../services'

export default {
  layout: 'user',
  components: {
    Circle2,
    Parallax,
    QrcodeVue
  },
  mixins: [ResizeMixin],
  data() {
    return {
      loading: false,
      titleBg: require('@/assets/images/home-parallax-2.jpg'),
      stikyKit: {
        options: {
          parent: '.sidebar-wrapper',
          offset_top: 118
        }
      },
      oldPassIsError: false,
      prevPassword: '',
      password: '',
      submitted: false,
      prevPasswordFieldType: 'password',
      passwordFieldType: 'password',
      owner: 0
    }
  },
  created() {
    const query = `account=${this.user?._id}&pageSize=1&pageNumber=1`
    realEstateService.ownerRealEstates(query).then(results => {
      this.owner = results.data.count || 0
    })
  },
  validations: {
    password: {
      required,
      goodPassword: password => {
        //a custom validator!
        return password.length >= 6
      }
    },
    prevPassword: {
      required
    },
    form: ['prevPassword', 'password']
  },
  mounted() {
    this.setSpinnerLoading(false)
  },
  computed: {
    ...mapState('accountState', ['configs', 'user']),
    ...mapState('userState', ['spinnerLoading']),
    passwordData() {
      return {
        prevPassword: this.prevPassword,
        password: this.password
      }
    },
    key() {
      return this.$route.fullPath
    },
    showFooter() {
      return this.$route.meta.showFooter
    },
    imageQr() {
      if (this.user.imageQr) {
        return this.user.imageQr
      }
      return false
    },
    qrcode() {
      return {
        value: `${window.location.origin}/properties/${this.user._id}`,
        size: 100
      }
    }
  },
  methods: {
    ...mapActions('accountState', ['updateProfile', 'logout']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    prevPasswordSwitchVisibility() {
      this.prevPasswordFieldType =
        this.prevPasswordFieldType === 'password' ? 'text' : 'password'
    },
    onSubmitForm() {
      this.submitted = true
      this.setSpinnerLoading(true)
      this.$v.form.$touch()
      var isValid = !this.$v.form.$invalid

      if (!isValid) {
        this.$toasted.show('من فضلك تأكد من جميع الحقول', {
          duration: 2000,
          type: 'error',
          icon: 'fa-times'
        })
        this.setSpinnerLoading(false)
      } else {
        this.updateProfile(this.passwordData)
          .then(() => {
            this.$toasted.show('تم تغيير كلمةالمرور بنجاح', {
              duration: 2000,
              type: 'success',
              icon: 'fa-check',
              onComplete: () => this.$router.push({ name: 'home' })
            })
            this.setSpinnerLoading(false)
          })
          .catch(err => {
            const message = err.response.data.message
            if (message.includes('the same')) {
              this.$toasted.show(
                'كلمة المرور الجديدة لا يمكن ان تكون نفس كلمة المرور القديمة',
                {
                  duration: 3000,
                  type: 'error',
                  icon: 'fa-close'
                }
              )
            } else {
              this.$toasted.show('كلمة المرور الحالية غير مطابقة', {
                duration: 2000,
                type: 'error',
                icon: 'fa-close'
              })
            }
            this.setSpinnerLoading(false)
          })
      }
    },
    navbarClick(route) {
      this.$router.push(route)
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.$store.dispatch('authState/logout')
        this.$router.push({ name: 'login' })
      }
    },
    beforeLeave() {
      this.loading = true
    },
    afterEnter() {
      this.loading = false
    },
    loggedOut() {
      this.logout()
    }
  }
}
</script>
<style lang="scss" scoped>
div.Masthead:after {
  background-color: rgb(61, 186, 126);
  opacity: 0.9;
}
</style>
